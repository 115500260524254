//export const HOST = 'https://apimv.ticservice.org:17100/';
export const HOST = process.env.REACT_APP_API_URL;
export const VERSION = 'api/v1/';

const APP_URL = {
    // AUTH
    LOGIN: `${HOST}${VERSION}auth/login/`,

    // ADMIN - APPOINTMENT
    SEARCH_PATIENTS_APPOINTMENTS: `${HOST}${VERSION}admin/appointment/searchPatient`,
    UPLOAD_EVIDENCES: `${HOST}${VERSION}admin/appointment/uploadFiles`,
    CREATE_APPOINTMENT: `${HOST}${VERSION}admin/appointment/create`,
    LIST_APPOINTMENTS: `${HOST}${VERSION}admin/appointment/list`,
    CHANGE_STATUS_APPOINTMENT: `${HOST}${VERSION}admin/appointment/updateStatus`,
    LIST_FILE_APPOINTMENTS: `${HOST}${VERSION}admin/appointment/listFiles`,
    LIST_APP_STATUS_USER: `${HOST}${VERSION}admin/appointment/listAppStatusUser`,
    COUNT_APPOINTMENT_TOTAL_DAY_MONTH: `${HOST}${VERSION}admin/appointment/CountAppointmentTotalDayMonth`,
    COUNT_APP_STATUS: `${HOST}${VERSION}admin/appointment/countAppStatusUser`,
    LIST_TRACKER: `${HOST}${VERSION}admin/appointment/listTracker`,

    // ADMIN - APPOINTMENT - REFFERED
    CREATE_REFFERED: `${HOST}${VERSION}admin/appointment/createReffered`,
    LIST_REFFERED_ALL: `${HOST}${VERSION}admin/appointment/listRefferedAll`,
    LIST_REFFERED_AGENT: `${HOST}${VERSION}admin/appointment/listReffered`,
    UPDATE_REFFERED: `${HOST}${VERSION}admin/appointment/updateReffered`,
    CREATE_TRACKER: `${HOST}${VERSION}admin/appointment/createTracker`,
    EDIT_DATA_USER: `${HOST}${VERSION}admin/appointment/edit`,

    // ADMIN - PARAMETER
    LOAD_INSURANCE: `${HOST}${VERSION}admin/parameter/list`,

    // CREATE FORM OBAMA CARE
    CREATE_FORM_OBAMA_CARE: `${HOST}${VERSION}admin/obamacare/create`,

    // PARAMETER
    LOAD_PARAMETER: `${HOST}${VERSION}admin/parameter/list/`,

    // LIST STATUS
    LIST_STATUS: `${HOST}${VERSION}admin/obamacare/listStatusTracker`,
    LIST_STATUS_SUPERVISOR: `${HOST}${VERSION}admin/obamacare/listStatusTrackersupervisor`,

    // SEARCH PATIENTS
    SEARCH_PATIENTS: `${HOST}${VERSION}admin/patients/search`,

    // Status Follow-up
    LIST_TRACKING: `${HOST}${VERSION}admin/obamacare/listTracker`,
    CREATE_TRACKING: `${HOST}${VERSION}admin/obamacare/createTracker`,
    UPDATE_TRACKING: `${HOST}${VERSION}admin/obamacare/updateTracker`,
    UPDATE_FORMOBAMACARE: `${HOST}${VERSION}admin/obamacare/editform`,

    // Upload
    UPLOADEXCELFILE: `${HOST}${VERSION}admin/upload/uploadexcel`,
    LISTPHONEAGENTALL: `${HOST}${VERSION}admin/upload/listPhoneAgentAll`,
    LISTAGENTALL: `${HOST}${VERSION}admin/upload/listAgentAll`,
    UPDATESTATUS: `${HOST}${VERSION}admin/upload/updateStatus`,

    LISTAGENTALLFILTER: `${HOST}${VERSION}admin/upload/listAgentAllFilter`,
    LISTAGENTFILTER: `${HOST}${VERSION}admin/upload/listAgentFilter`,
    LISTAGENTFILTERSTATUS: `${HOST}${VERSION}admin/upload/listAgentFilterStatus`,

    // USERS
    LISTUSERS: `${HOST}${VERSION}admin/users/list`,
    CREATEUSERS: `${HOST}${VERSION}admin/users/create`,
    UPDATEUSERS: `${HOST}${VERSION}admin/users/update`,
    UPDATESTATUSUSERS: `${HOST}${VERSION}admin/users/updateStatus`,
    UPDATEPASSUSERS: `${HOST}${VERSION}admin/users/updatePass`,
    LISTAGENT: `${HOST}${VERSION}admin/users/listAgent`,

    // CRUD AGENT
    CREATEASSIGNEDAGENT: `${HOST}${VERSION}admin/assignedagent/create`,
    UPDATEASSIGNEDAGENT: `${HOST}${VERSION}admin/assignedagent/update`,
    UPDATESTATUSAGENT: `${HOST}${VERSION}admin/assignedagent/updatestatus`,
    LISTASSIGNEDAGENT: `${HOST}${VERSION}admin/assignedagent/list`,

    // CREATE LIST PHONE CARE CROSS
    CREATELISTCARECROSS: `${HOST}${VERSION}admin/carecross/createlistphone`,
    LISTPHONECARECROSS: `${HOST}${VERSION}admin/carecross/listphone`,

    // REPORTS STATUS PHONE 
    REPORTSTATUSPHONE: `${HOST}${VERSION}admin/obamacare/reports`,
    REPORTSTATUSPHONENOANSWERAGENT: `${HOST}${VERSION}admin/obamacare/reportnumbernoansweragent`,
    DELETEAPPOINTMENT: `${HOST}${VERSION}admin/obamacare/deleteAppointment`,

    // UPDATE STATUS PHONE AGENT
    UPDATESTATUSPHONEAGENT: `${HOST}${VERSION}admin/obamacare/updatestatusphoneagent`,

    // APPOINTMENT SALES
    CREATESALES: `${HOST}${VERSION}admin/obamacare/createsales`,
    CREATESALESPENDING: `${HOST}${VERSION}admin/obamacare/createsalespending`,
    UPDATESALES: `${HOST}${VERSION}admin/obamacare/updatesales`,
    UPDATESALESDATA: `${HOST}${VERSION}admin/obamacare/updatesalesData`,
    SEARCHSALESPRE: `${HOST}${VERSION}admin/obamacare/searchsalespre`,
    SEARCHSALESDEPENDENT: `${HOST}${VERSION}admin/obamacare/searchsalesdepent`,
    SEARCHSALESPLAN: `${HOST}${VERSION}admin/obamacare/searchsalesplan`,
    SEARCHSALESBENEFICIARIES: `${HOST}${VERSION}admin/obamacare/searchsalesbeneficiaries`,
    SEARCHUPLOADSALESDOCUMENT: `${HOST}${VERSION}admin/obamacare/searchuploadsalesdocument`,
    DELETEUPLOADSALES: `${HOST}${VERSION}admin/obamacare/deleteuploadsales`,
    SEARCHSALESAGENTS: `${HOST}${VERSION}admin/obamacare/searchsalesagents`,
    SEARCHSALESPROCESSOR: `${HOST}${VERSION}admin/obamacare/searchsalesprocessor`,
    SEARCHSALESOBSERVATION: `${HOST}${VERSION}admin/obamacare/searchsalesobservation`,
    TOTALSALES: `${HOST}${VERSION}admin/obamacare/totalSales`,
    TOTALSALESLASTDAYS: `${HOST}${VERSION}admin/obamacare/totalSalesLastDays`,
    TOTALSALESAGENTS: `${HOST}${VERSION}admin/obamacare/totalSalesAgents`,
    TOTALSALESMONTH: `${HOST}${VERSION}admin/obamacare/totalSalesMonth`,
    TOTALSALESAGENTSUSER: `${HOST}${VERSION}admin/obamacare/totalSalesAgentsUser`,
    TOTALSALESMONTHUSER: `${HOST}${VERSION}admin/obamacare/totalSalesMonthUser`,
    TOTALSALESMONTHLASTVSCURRENT: `${HOST}${VERSION}admin/obamacare/totalSalesMonthLastVsCurrent`,
    LISTYEARS: `${HOST}${VERSION}admin/obamacare/listYears`,
    LISTGOALS: `${HOST}${VERSION}admin/obamacare/listGoals`,
    CREATEGOALS: `${HOST}${VERSION}admin/obamacare/createGoals`,
    EDITGOALS: `${HOST}${VERSION}admin/obamacare/editGoals`,
    SEARCHNOTEPAD: `${HOST}${VERSION}admin/obamacare/searchNotePad`,
    SAVENOTEPAD: `${HOST}${VERSION}admin/obamacare/saveNotePad`,
    LISTSALESSUPERVISOR: `${HOST}${VERSION}admin/obamacare/listSalesSupervisor`,
    EXPORTFILESXLXS: `${HOST}${VERSION}admin/obamacare/exportFileXLXS`,
    DELETEFILESXLXS: `${HOST}${VERSION}admin/obamacare/deleteFileXLXS`,

    LISTPRENEWAPPOINTMENTSALES: `${HOST}${VERSION}admin/obamacare/listprenewappointmentsales`,
    LISTAPPOINTMENTSALESDATE: `${HOST}${VERSION}admin/obamacare/listappointmentsalesdate`,
    SEARCHAPPOINTMENTSALES: `${HOST}${VERSION}admin/obamacare/searchappointmentsales`,
    SEACRHSALES: `${HOST}${VERSION}admin/obamacare/searchsales`,
    UPDATEFORMSALES: `${HOST}${VERSION}admin/obamacare/updateformsales`,
    DELETESALES: `${HOST}${VERSION}admin/obamacare/deletesales`,
    UPDATESTATUSSALES: `${HOST}${VERSION}admin/obamacare/updateStatusSales`,

    // FORM SUPLEMENTAL
    UPLOADFORMSUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/uploadformsuplemental`,
    LISTFORMSUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/listformsuplemental`,
    UPDATEFORMSUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/updateformsuplemental`,
    DELETESUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/deletesuplemental`,

    // AGENCY
    LISTAGENCY: `${HOST}${VERSION}admin/agency/listagency`,
    CREATEAGENCY: `${HOST}${VERSION}admin/agency/createagency`,
    UPDATEAGENCY: `${HOST}${VERSION}admin/agency/updateagency`,

}

export default APP_URL